import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import ReactExport from "react-data-export";
import Cookies from "universal-cookie";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function YearEmpReport(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[3];
  const [data, setData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let apiLink =
        "api/year_total/" +
        props.match.params.year +
        "/" +
        props.match.params.employee_id;
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
    };

    const fetchEmp = async () => {
      let apiLink = "api/employee/" + props.match.params.employee_id;
      const res = await axios.get(apiLink);
      setEmployee(res.data);
    };

    fetchEmp();
    fetchData();
  }, []);

  const handleBack = () => {
    props.history.goBack();
  };

  const getTotalAmt = data.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.total_pay)),
    0
  );

  const getTotalHours = data.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.total_ot_hours)),
    0
  );

  const getTotalNetAmt = data.reduce(
    (total, currentValue) =>
      (total =
        total +
        parseFloat(
          currentValue.total_pay - currentValue.total_pay * employee.ot_tax
        )),
    0
  );
  const dataSet = [
    {
      columns: [{ title: "Company Name" }, { title: employee.company_name }],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "Employee Name" },
        { title: employee.last_name + ", " + employee.first_name },
      ],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [{ title: "Department" }, { title: employee.department }],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [{ title: "Position" }, { title: employee.position }],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "Tax Rate" },
        { title: "" + employee.ot_tax * 100 + " %" },
      ],
      data: [["", ""]],
    },
    {
      columns: [
        { title: "Month" },
        { title: "Total OT Hours" },
        { title: "Total OT Pay (Gross)" },
        { title: "Tax Amount" },
        { title: "Total OT Pay (Net)" },
      ],
      data: data.map((order, index) => {
        return [
          { value: order.month_name },
          { value: parseFloat(order.total_ot_hours).toFixed(2) },
          { value: parseFloat(order.total_pay).toFixed(2) },
          {
            value: parseFloat(order.total_pay * employee.ot_tax).toFixed(
              2
            ),
          },
          {
            value: parseFloat(
              order.total_pay - order.total_pay * employee.ot_tax
            ).toFixed(2),
          },
        ];
      }),
    },
    {
      columns: [
        { title: "TOTAL" },
        { title: "" + getTotalHours.toFixed(2) },
        { title: "" + getTotalAmt.toFixed(2) },
        { title: "" + (getTotalAmt - getTotalNetAmt).toFixed(2) },
        { title: "" + getTotalNetAmt.toFixed(2) },
      ],
      data: [["", "", "", "", "", "", ""]],
    },
  ];

  return (
    <div>
      <Header />
      <div>
        {ability.permission > 0 ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h3 className="pb-10">Year {props.match.params.year} Total</h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <button
                  className="btn btn-sm btn-danger float-right "
                  onClick={handleBack}
                >
                  Back to Previous Page
                </button>
                {ability.permission > 1 ? (
                  <ExcelFile
                    element={
                      <button className="btn btn-sm btn-success float-right mr-10">
                        <i class="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                        Export to Excel
                      </button>
                    }
                    filename={
                      "Year " +
                      props.match.params.year +
                      " Total " +
                      employee.last_name +
                      ", " +
                      employee.first_name
                    }
                  >
                    <ExcelSheet dataSet={dataSet} name="Total Overtime" />
                  </ExcelFile>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="card" style={{ height: "93%" }}>
                  <div className="card-header card-header-action">
                    <h5>Employee Details</h5>
                    <div className="d-flex align-items-center card-action-wrap-tools"></div>
                  </div>
                  <div className="card-body">
                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Company</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.company_name}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Name</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.last_name + ", " + employee.first_name}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Department</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.department}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Position</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.position}
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Tax Rate</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.ot_tax * 100} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="table-responsive mb-10">
                  <table className="table table-sm table-bordered table-striped mb-0">
                    <thead className="thead-secondary">
                      <tr>
                        <th>Month</th>
                        <th>Total OT Hours</th>
                        <th>Total OT Pay (Gross)</th>
                        <th>Tax Amount</th>
                        <th>Total OT Pay (Net)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((detail) => (
                        <tr key={detail.id}>
                          <td>{detail.month_name}</td>
                          <td className="text-right">
                            {parseFloat(detail.total_ot_hours).toFixed(2)}
                          </td>
                          <td className="text-right">
                            {parseFloat(detail.total_pay).toFixed(2)}
                          </td>
                          <td className="text-right">
                            {parseFloat(
                              detail.total_pay * employee.ot_tax
                            ).toFixed(2)}
                          </td>
                          <td className="text-right">
                            {parseFloat(
                              detail.total_pay -
                                detail.total_pay * employee.ot_tax
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      {data.length > 0 ? (
                        <>
                          <tr>
                            <td className="text-right">
                              <strong>Totals</strong>
                            </td>
                            <td className="text-right">
                              <strong>{getTotalHours.toFixed(2)}</strong>
                            </td>
                            <td className="text-right">
                              <strong>{getTotalAmt.toFixed(2)}</strong>
                            </td>
                            <td className="text-right">
                              <strong>
                                {(getTotalAmt - getTotalNetAmt).toFixed(2)}
                              </strong>
                            </td>
                            <td className="text-right">
                              <strong>{getTotalNetAmt.toFixed(2)}</strong>
                            </td>
                          </tr>
                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h1 className="m-0 text-center">
            The Credentials Supplied Are Not Sufficient To Access This Module
            <br />
            Please Contact Administrator
          </h1>
        )}
      </div>
    </div>
  );
}
