import React, { useState, useEffect } from "react";
import axios from "axios";
import { dateFormatter } from "../../../components/DtFormatter";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import ResetUser from "./ResetUser";

export default function Users() {
  const [data, setData] = useState([]);
  const [roleDD, setRoleDD] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/users";
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);
    };

    const fetchRole = async () => {
      let apiLink = "api/roles";
      const res = await axios.get(apiLink);
      setRoleDD(res.data);
    };

    if (!fileLoaded) {
      fetchData();
      fetchRole();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["name"].toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <AddUser role={roleDD} parentCallback={handleAddCallback} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-wrap">
            <div className="row mb-10">
              <div className="col-md-6">
                <label>
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    onChange={handleRecordPerPage}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>{" "}
                items
              </div>
              <div className="col-md-6">
                <input
                  type="search"
                  className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="table-responsive mb-10">
              <table className="table table-sm table-bordered table-striped mb-0">
                <thead className="thead-secondary">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Last Date Updated</th>
                    <th>Updated By</th>
                    <th style={{ width: "100px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    currentData.map((user) => (
                      <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role_name}</td>
                        <td>{user.status}</td>
                        <td>
                          {dateFormatter(
                            user.updated_at,
                            "MMM dd, yyyy hh:mm aa"
                          )}
                        </td>
                        <td>{user.updated_by}</td>
                        <td>
                          <div className="btn-group">
                            <EditUser
                              role={roleDD}
                              user={user}
                              parentCallback={handleAddCallback}
                            />
                            <ResetUser user={user}/>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No Data to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="drew-pagination">
            {filteredData.length > 0
              ? "Showing " +
                (startIndex + 1) +
                " to " +
                (endIndex > filteredData.length
                  ? filteredData.length
                  : endIndex) +
                " of " +
                filteredData.length
              : ""}
            <ul class="pagination ml-2">
              {pages.map((number) => (
                <li
                  key={number}
                  id={number}
                  className={
                    currentPage == number
                      ? "paginate_button page-item active"
                      : "paginate_button page-item"
                  }
                  onClick={() => setCurrentPage(number)}
                >
                  <a class="page-link">{number}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
