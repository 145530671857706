import React from "react";
import Header from "../components/Header";

export default function Public(props) {

  return (
    <div>
      <Header />
      <div className="container-fluid landing" style={{"width": "100%",height: "750px"}}>
        <div >
          {/* <h3 className="pb-10">Dashboard</h3> */}
        </div>
      </div>
    </div>
  );
}
