import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { timeFormatter } from "../../components/DtFormatter";
import { Edit } from "@material-ui/icons";
import TimePicker from "react-bootstrap-time-picker";
import { timeFromInt } from "time-number";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditSchedule extends Component {
  constructor(props) {
    super();
    this.state = {
      showEditModal: false,
      emp_id: props.emp_id,
      id: props.id,
      timeIn: props.ti,
      timeOut: props.to,
      n_timeIn: 0,
      n_timeOut: 0,
      is_restday: props.is_restday,
      prev_timeIn: props.ti,
      prev_timeOut: props.to,
      prev_is_restday: props.is_restday,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleTimeChange(e, type) {
    if (type === "ti") {
      // this.setState({ timeIn: timeFromInt(e), n_timeIn: e });
      this.setState({ timeIn: e, n_timeIn: e });
    }

    if (type === "to") {
      // this.setState({ timeOut: timeFromInt(e), n_timeOut: e });
      this.setState({ timeIn: e, n_timeIn: e });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let dt1 = new Date("January 1, 2020 00:00:00");
    let dt2 = new Date("January 1, 2020 00:00:00");
    let data_new_value = "REST DAY";
    if (this.state.is_restday == 0) {
      dt1 = new Date("January 1, 2020 " + this.state.timeIn);
      dt2 = new Date("January 1, 2020 " + this.state.timeOut);
      if (this.state.n_timeIn > this.state.n_timeOut)
        dt2 = new Date("January 2, 2020 " + this.state.timeOut);

      data_new_value =
        timeFormatter(this.state.timeIn) +
        " - " +
        timeFormatter(this.state.timeOut);
    }
    let diff = Math.abs(
      (dt2.getTime() - dt1.getTime()) / 1000 / (60 * 60)
    );

    let data_prev_value = "REST DAY";
    if (this.state.prev_is_restday == 0) {
      data_prev_value =
        timeFormatter(this.state.prev_timeIn) +
        " - " +
        timeFormatter(this.state.prev_timeOut);
    }

    let dataShift = {
      time_in: timeFormatter(this.state.timeIn, "HH:mm:ss"),
      time_out: timeFormatter(this.state.timeOut, "HH:mm:ss"),
      is_restday: this.state.is_restday,
      required_work_hours: diff,
      updated_by: cookies.get('email'),
    };

    let dataShiftHistory = {
      emp_id: this.state.emp_id,
      shift_id: this.state.id,
      prev_value: data_prev_value,
      new_value: data_new_value,
      updated_by: cookies.get('email'),
      created_by: cookies.get('email'),
    };

    axios.put("api/shift/" + this.state.id, dataShift).then((res) => {
      axios
        .post("api/shift_history", dataShiftHistory)
        .then((res) => {
          this.props.parentCallback(true);
          this.setState({ showEditModal: false });
        })
        .catch((err) => {});
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm"
          title="Edit Schedule"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>
              Edit {this.props.day} {} Schedule
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Rest Day</label>
                  <div className="row">
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          class="custom-control-input"
                          value="1"
                          onChange={(e) => {
                            this.setState({ is_restday: e.target.value });
                          }}
                          checked={this.state.is_restday == 1 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="customRadio1">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          class="custom-control-input"
                          value="0"
                          onChange={(e) => {
                            this.setState({ is_restday: e.target.value });
                          }}
                          checked={this.state.is_restday == 0 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="customRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Time In</label>
                  <input
                    type="time"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ timeIn: e.target.value })}
                    defaultValue={this.state.timeIn}
                    disabled={this.state.is_restday == 1 ? "disabled" : ""}
                  />
                  <TimePicker
                    className="form-control"
                    step={15}
                    onChange={(e) => this.handleTimeChange(e, "ti")}
                    value={this.state.timeIn}
                    disabled={this.state.is_restday == 1 ? "disabled" : ""}
                    style={{
                      visibility: "hidden",
                      height: "0px",
                      padding: "0px",
                      margin: "0px",
                    }}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Time Out</label>
                  <input
                    type="time"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ timeOut: e.target.value })}
                    defaultValue={this.state.timeOut}
                    disabled={this.state.is_restday == 1 ? "disabled" : ""}
                  />
                  <TimePicker
                    className="form-control"
                    step={15}
                    onChange={(e) => this.handleTimeChange(e, "to")}
                    value={this.state.timeOut}
                    disabled={this.state.is_restday == 1 ? "disabled" : ""}
                    style={{
                      visibility: "hidden",
                      height: "0px",
                      padding: "0px",
                      margin: "0px",
                    }}
                  />
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
