import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import Header from "../../components/Header";
import EditCompany from "./EditCompany";
import EditHoliday from "./EditHoliday";
import AddHoliday from "./AddHoliday";
import DeleteHoliday from "./DeleteHoliday";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class CompanyDetail extends Component {
  state = {
    ability: cookies.get("ability")[0],
    companyDetails: [],
    holidayDetails: [],
    updateOccured: false,
    schemaDD: [],
  };
  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
    axios.get("api/schema").then((res) => {
      this.setState({ schemaDD: res.data });
    });
  }

  getDetails = () => {
    const id = this.props.match.params.id;
    axios.get("api/company/" + id).then((res) => {
      this.setState({ companyDetails: res.data });
    });

    axios.get("api/holiday/" + id).then((res) => {
      this.setState({ holidayDetails: res.data });
    });
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleCallback = (cb) => {
    if (cb) {
      this.getDetails();
      this.setState({ updateOccured: true });
    }
  };

  componentDidUpdate() {}

  render() {
    return (
      <div>
        <Header />
        <div className="">
          {this.state.ability.permission > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Company View</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <button
                    className="btn btn-sm btn-danger float-right "
                    onClick={this.handleBack}
                  >
                    Back to Previous Page
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>Company Details</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools">
                      {this.state.ability.permission > 2 ? (
                        <EditCompany
                          schemaDD={this.state.schemaDD}
                          companyDetail={this.state.companyDetails}
                          parentCallback={this.handleCallback}
                        />):("")}
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Code</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.companyDetails.code}
                        </div>
                        <div className="col-xl-2 col-sm-10">
                          <strong>Default Schema</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.companyDetails.schema_name}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Name</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.companyDetails.name}
                        </div>
                        <div className="col-xl-2 col-sm-10">
                          <strong>Night Differentials</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.companyDetails.f_night_diff_in +
                            " - " +
                            this.state.companyDetails.f_night_diff_out}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Minimum Overtime(Hour)</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.companyDetails.min_overtime}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>Company Holiday</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools">
                      {this.state.ability.permission > 1 ? (
                        <AddHoliday
                          companyId={this.state.companyDetails.id}
                          parentCallback={this.handleCallback}
                        />):("")}
                      </div>
                    </div>
                    <div className="card-body">
                      <div class="table-responsive mb-10">
                        <table class="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr>
                              <th>Type</th>
                              <th>Name</th>
                              <th>Date</th>
                              <th>Last Update</th>
                              <th style={{ width: "110px" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.holidayDetails.map((holiday) => (
                              <tr key={holiday.id}>
                                <td>{holiday.holiday_type_name}</td>
                                <td>{holiday.name}</td>
                                <td>{holiday.f_holiday_date}</td>
                                <td>{holiday.f_updated_at}</td>
                                <td>
                                  <div className="btn-group">
                                  {this.state.ability.permission > 2 ? (
                                    <EditHoliday
                                      holidayDetail={holiday}
                                      parentCallback={this.handleCallback}
                                    />) :("") }
                                    {this.state.ability.permission > 3 ? (
                                    <DeleteHoliday
                                      name={holiday.name}
                                      id={holiday.id}
                                      parentCallback={this.handleCallback}
                                    />) :("") }
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(CompanyDetail);
