import React from "react";
import { Link } from "react-router-dom";

export default function SessExpired() {
  return (
    <div className="hk-wrapper">
      <div className="hk-pg-wrapper hk-auth-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 pa-0">
              <div className="auth-form-wrap pt-xl-0 pt-70 landing">
                <div className="auth-form w-xl-35 w-lg-65 w-sm-85 w-100 card pa-25 shadow-lg">
                  <div className="media text-center mb-5">
                    <div class="media-body">
                      <h2>Logged out due to inactivity</h2>
                    </div>
                  </div>
                  <p className="font-14 text-center mt-15">
                    <Link className="btn btn-success" to="/">Login Back</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
