
import { format } from "date-fns";
export function timeFormatter(time, timeFormat = "hh:mm aa") {
  let dates = new Date("2000-01-01 " + time);
  return format(dates, timeFormat);
}


export function dateFormatter(date, dateFormat = "MMM dd, yyyy") {
  if(date != null){
    let dates = new Date(date);
    return format(dates, dateFormat);
  }
}
