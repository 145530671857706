import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { timeFormatter } from "../../components/DtFormatter";
import { Link } from "react-router-dom";
import axios from "axios";
import { FindInPage, Add } from "@material-ui/icons";
import AddCompany from "./AddCompany";
import DeleteCompany from "./DeleteCompany";
import Cookies from "universal-cookie";

export default function Company() {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[0];
  const [data, setData] = useState([]);
  const [schemaDD, setSchemaDD] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/company";
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);
    };

    const fetchDD = async () => {
      const getSchema = await axios.get("api/schema");
      setSchemaDD(getSchema.data);
    };

    if (!fileLoaded) {
      fetchDD();
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["name"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["code"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["schema_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <Header />
      <div>
        {ability.permission > 0 ? (
          <div className="container-fluid">
            <div className="row mb-10">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h3>Companies</h3>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                {ability.permission > 1 ? (
                  <AddCompany
                    schemaDD={schemaDD}
                    parentCallback={handleAddCallback}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-wrap">
                  <div className="row mb-10">
                    <div className="col-md-6">
                      <label>
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={handleRecordPerPage}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>{" "}
                      items
                    </div>
                    <div className="col-md-6">
                      <input
                        type="search"
                        className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                        placeholder="Search"
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="table-responsive mb-10">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-secondary">
                        <tr>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Night Differential</th>
                          <th>Minimum Overtime(Hour)</th>
                          <th>Default Scheam</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.length > 0 ? (
                          currentData.map((company) => (
                            <tr key={company.id}>
                              <td>{company.code}</td>
                              <td>{company.name}</td>
                              <td>
                                {timeFormatter(company.night_diff_in) +
                                  " - " +
                                  timeFormatter(company.night_diff_out)}
                              </td>
                              <td>{company.min_overtime}</td>
                              <td>{company.schema_name}</td>
                              <td>
                                <div className="btn-group">
                                  <Link
                                    to={{
                                      pathname: `/company/view/${company.id}`,
                                      query: `/company`,
                                    }}
                                    className="btn btn-sm btn-outline-primary"
                                    title={"View " + company.name + " Details"}
                                  >
                                    <FindInPage />
                                  </Link>
                                  {ability.permission > 3 ? (
                                    <DeleteCompany
                                      name={company.name}
                                      id={company.id}
                                      parentCallback={handleAddCallback}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Data to show
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="drew-pagination">
                  {filteredData.length > 0
                    ? "Showing " +
                      (startIndex + 1) +
                      " to " +
                      (endIndex > filteredData.length
                        ? filteredData.length
                        : endIndex) +
                      " of " +
                      filteredData.length
                    : ""}
                  <ul class="pagination ml-2">
                    {pages.map((number) => (
                      <li
                        key={number}
                        id={number}
                        className={
                          currentPage == number
                            ? "paginate_button page-item active"
                            : "paginate_button page-item"
                        }
                        onClick={() => setCurrentPage(number)}
                      >
                        <a class="page-link">{number}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h1 className="m-0 text-center">
            The Credentials Supplied Are Not Sufficient To Access This Module
            <br />
            Please Contact Administrator
          </h1>
        )}
      </div>
    </div>
  );
}
