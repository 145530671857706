import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const permission = [
  { value: 0, label: "No Access" },
  { value: 1, label: "Read Only" },
  { value: 2, label: "Add" },
  { value: 3, label: "Add & Edit" },
  { value: 4, label: "Full Access" },
];

export default class EditRoles extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      showEditModal: false,
      disableSubmit: false,
      updated: false,
      name: props.role.name,
      perms: [],
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  componentDidMount() {
    this.getPermissions();
  }

  getPermissions = () => {
    axios.get("api/roles_permission/" + this.props.role.id).then((res) => {
      this.setState({ perms: res.data });
    });
  };
  componentDidUpdate() {
      if(this.state.updated){
        this.getPermissions();
        this.setState({updated: false})
      }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.put("api/roles/" + this.props.role.id, this.state).then((res) => {
        for(var key in this.state.perms){
            var module = this.state.perms[key]['module'];
            if(typeof this.state[module] !== "undefined"){
                axios.put("api/roles_permission/" + this.state.perms[key]['id'], {permission: this.state[module]})
            }
        }
      this.props.parentCallback(true);
      this.setState({ showEditModal: false, disableSubmit: false, updated: true });
    });
    
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Update Role"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Update Role: {this.props.role.name}</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                    defaultValue={this.props.role.name}
                  />
                </div>
              </div>
              {this.state.perms.map((permDB) => (
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <label>{permDB.module}</label>
                    <select
                      class="form-control custom-select"
                      required
                      onChange={(e) =>
                        this.setState({ [permDB.module]: e.target.value })
                      }
                    >
                      <option value="">Select Permission</option>
                      {permission.map((perm) =>
                        permDB.order === 5 ? (
                          perm.value === 0 || perm.value === 4 ? (
                            <option
                              key={perm.value}
                              value={perm.value}
                              selected={
                                perm.value == permDB.permission
                                  ? "selected"
                                  : ""
                              }
                            >
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        ) : permDB.order === 3 || permDB.order === 4 ? (
                          perm.value !== 3 && perm.value !== 2 ? (
                            <option
                              key={perm.value}
                              value={perm.value}
                              selected={
                                perm.value == permDB.permission
                                  ? "selected"
                                  : ""
                              }
                            >
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        ) : (
                          <option
                            key={perm.value}
                            value={perm.value}
                            selected={
                              perm.value == permDB.permission ? "selected" : ""
                            }
                          >
                            {perm.label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              ))}
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
