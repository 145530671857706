import React, { Component } from "react";
import { withRouter } from "react-router";
import { timeFormatter, dateFormatter } from "../../components/DtFormatter";
import Header from "../../components/Header";
import axios from "axios";
import EditSchedule from "./EditSchedule";
import EditEmployee from "./EditEmployee";
import Cookies from "universal-cookie";
import AddWeekSchedule from "./AddWeekSchedule";
const cookies = new Cookies();
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const empStatus = ["Probationary", "Regular", "Resigned"];

class ViewEmployee extends Component {
  state = {
    ability: cookies.get("ability")[0],
    empDetails: [],
    shiftDetails: [],
    historyData: [],
    shiftEdited: false,
    companyDD: [],
    schemaDD: [],
    totalWorkHours: 0,
    historyCurrentPage: 1,
    historyRecordsPerPage: 5,
  };

  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
    axios.get("api/company").then((res) => {
      this.setState({ companyDD: res.data });
    });

    axios.get("api/schema").then((res) => {
      this.setState({ schemaDD: res.data });
    });
  }

  componentDidUpdate() {
    if (this.state.shiftEdited) {
      this.getDetails();
      this.setState({ shiftEdited: false });
    }
  }

  getDetails = () => {
    const empId = this.props.match.params.id;
    axios.get("api/employee/" + empId).then((res) => {
      this.setState({ empDetails: res.data });
    });

    axios.get("api/shift/" + empId).then((res) => {
      this.setState({ shiftDetails: res.data });
    });

    axios.get("api/shift_history/" + empId).then((res) => {
      this.setState({ historyData: res.data });
    });
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleEditShiftCallback = (cb) => {
    if (cb) {
      this.setState({ shiftEdited: true });
    }
  };

  render() {
    const pages = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        this.state.historyData.length / this.state.historyRecordsPerPage
      );
      i++
    ) {
      pages.push(i);
    }

    const startIndex =
      this.state.historyCurrentPage * this.state.historyRecordsPerPage -
      this.state.historyRecordsPerPage;
    const endIndex = startIndex + this.state.historyRecordsPerPage;
    const currentData = this.state.historyData.slice(startIndex, endIndex);

    return (
      <div>
        <Header />
        <div className="">
          {this.state.ability.permission > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-10">
                  <h3>Employee View</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <button
                    className="btn btn-sm btn-danger float-right "
                    onClick={this.handleBack}
                  >
                    Back to Previous Page
                  </button>
                </div>
              </div>

              <div className="row mb-20">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card" style={{ height: "100%" }}>
                    <div className="card-header  card-header-action">
                      <h5>Employee Details</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools">
                        {this.state.ability.permission > 2 ? (
                          <EditEmployee
                            empDetail={this.state.empDetails}
                            companyDD={this.state.companyDD}
                            schemaDD={this.state.schemaDD}
                            parentCallback={this.handleEditShiftCallback}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Name</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.first_name}{" "}
                          {this.state.empDetails.last_name}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Employee #</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.emp_no}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Date of Birth</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {dateFormatter(this.state.empDetails.date_of_birth)}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Company</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.company_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Position</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.position}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Department</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.department}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Company Email</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.company_email}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Contact #</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.contact_number}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Status</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {empStatus[this.state.empDetails.emp_status]}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Hire Date</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {dateFormatter(this.state.empDetails.date_hired)}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Schema</strong>
                        </div>
                        <div className="col-xl-7 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.schema_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Rate(Hours)</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {(1 * this.state.empDetails.hour_rate).toFixed(2)}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Tax Rate(%)</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.empDetails.ot_tax * 100} %
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Total Required Work(Hours)</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {(
                            1 * this.state.empDetails.required_working_hours
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card" style={{ height: "100%" }}>
                    <div className="card-header  card-header-action">
                      <h5>Shift Schedule</h5>
                      {/* <div className="d-flex align-items-center card-action-wrap-tools">
                        {this.state.ability.permission > 2 ? (
                          <AddWeekSchedule/>
                        ) : (
                          ""
                        )}
                      </div> */}
                    </div>
                    <div className="card-body">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr>
                              <th>Shift Day</th>
                              <th>From-to</th>
                              <th>Compute full hours?</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.shiftDetails.map((shift) => (
                              <tr key={shift.id}>
                                <td>{days[shift.sched_day - 1]}</td>
                                <td>
                                  {shift.is_restday == 1
                                    ? "REST DAY"
                                    : timeFormatter(shift.time_in) +
                                      " - " +
                                      timeFormatter(shift.time_out)}
                                </td>
                                <td>{shift.required_work_hours}</td>
                                <td>
                                  {this.state.ability.permission > 2 ? (
                                    <EditSchedule
                                      id={shift.id}
                                      emp_id={shift.emp_id}
                                      day={days[shift.sched_day - 1]}
                                      ti={shift.time_in}
                                      to={shift.time_out}
                                      is_restday={shift.is_restday}
                                      parentCallback={
                                        this.handleEditShiftCallback
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <h5 className="card-header">Shift History</h5>
                    <div className="card-body">
                      <div class="table-responsive mb-10">
                        <table class="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr>
                              <th>Shift Day</th>
                              <th>Previous Value</th>
                              <th>New Value</th>
                              <th>Date Changed</th>
                              <th>Changed By</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((history) => (
                              <tr key={history.id}>
                                <td>{history.shift_day}</td>
                                <td>{history.prev_value}</td>
                                <td>{history.new_value}</td>
                                <td>
                                  {dateFormatter(
                                    history.created_at,
                                    "MMM dd, yyyy hh:mm bb"
                                  )}
                                </td>
                                <td>{history.created_by}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="drew-pagination">
                        {currentData.length > 0
                          ? "Showing " +
                            (startIndex + 1) +
                            " to " +
                            (endIndex > currentData.length
                              ? currentData.length
                              : endIndex) +
                            " of " +
                            currentData.length
                          : ""}

                        <ul class="pagination ml-2">
                          {pages.map((number) => (
                            <li
                              key={number}
                              id={number}
                              className={
                                this.state.historyCurrentPage == number
                                  ? "paginate_button page-item active"
                                  : "paginate_button page-item"
                              }
                              onClick={() =>
                                this.setState({ historyCurrentPage: number })
                              }
                            >
                              <a class="page-link">{number}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(ViewEmployee);
