import React, { Component } from "react";
import Header from "../../components/Header";
import { withRouter } from "react-router";
import { Edit, ChromeReaderMode } from "@material-ui/icons";
import axios from "axios";
import Users from "./users/Users";
import Roles from "./user_roles/Roles";

export default function Administration() {
  return (
    <div>
      <Header />
      <div>
        <div className="container-fluid">
          <h3 className="pb-10">Site Management</h3>

          <div>
            <div className="container-fluid">
              <div class="card card-tabs">
                <div class="card-header p-0 pt-1">
                  <ul
                    class="nav nav-tabs"
                    id="custom-tabs-two-tab"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="custom-tabs-two-home-tab"
                        data-toggle="pill"
                        href="#user"
                        role="tab"
                        aria-controls="custom-tabs-two-home"
                        aria-selected="true"
                      ><i className="dropdown-icon zmdi zmdi-accounts-alt"></i>
                        &nbsp;Users
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-two-home-tab"
                        data-toggle="pill"
                        href="#user-roles"
                        role="tab"
                        aria-controls="custom-tabs-two-home"
                        aria-selected="true"
                      ><i className="dropdown-icon zmdi zmdi-accounts-list"></i>
                        &nbsp;Users Roles
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="tab-content" id="custom-tabs-two-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="user"
                      role="tabpanel"
                      aria-labelledby="custom-tabs-two-home-tab"
                    >
                      <Users/>
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="user-roles"
                      role="tabpanel"
                      aria-labelledby="custom-tabs-two-home-tab"
                    >
                        
                      <Roles/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
