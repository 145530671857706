import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditUser extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      showEditModal: false,
      disableSubmit: false,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.put("api/users/"+this.props.user.id, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showEditModal: false, disableSubmit: false });
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Update User"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4> Update User: {this.props.user.name}</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                    defaultValue={this.props.user.name}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ email: e.target.value })}
                    defaultValue={this.props.user.email}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Role</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ user_role: e.target.value })
                    }
                  >
                    <option value="">Select Role</option>
                    {this.props.role.map((role) => (
                      <option key={role.id} value={role.id} selected={
                        role.id == this.props.user.user_role ? "selected" : ""
                      }>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
