import React, { Component } from "react";
import Header from "../../components/Header";
import { withRouter } from "react-router";
import { Edit, ChromeReaderMode } from "@material-ui/icons";
import axios from "axios";

class Attendance extends Component {
  state = {
    empDetails: [],
    coDetails: [],
  };
  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
  }

  getDetails = () => {
    const empId = this.props.match.params.employee_id;
    const coId = this.props.match.params.cutoff_id;
    axios.get("api/employee/" + empId).then((res) => {
      this.setState({ empDetails: res.data });
    });

    axios.get("api/emp_cutoff/" + coId + "/" + empId).then((res) => {
      this.setState({ coDetails: res.data });
    });
  };

  componentDidUpdate() {}

  handleBack() {
    this.props.history.goBack();
  }
  render() {
    return (
      <div>
        <Header />
        <div>
          <div className="container-fluid">
            <div className="row pb-10">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-10">
                <h3>Employee Cutoff View</h3>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <button
                  className="btn btn-sm btn-danger float-right "
                  onClick={this.handleBack}
                >
                  Back to Previous Page
                </button>
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <h5 className="card-header">Employee Details</h5>
                  <div className="card-body">
                    <div className="row mb-10">
                      <div className="col-xl-2 col-sm-10">
                        <strong>Company</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {this.state.empDetails.company_name}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-2 col-sm-10">
                        <strong>Name</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {this.state.empDetails.first_name}{" "}
                        {this.state.empDetails.last_name}
                      </div>
                      <div className="col-xl-2 col-sm-10">
                        <strong>Employee #</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {this.state.empDetails.emp_no}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-2 col-sm-10">
                        <strong>Position</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {this.state.empDetails.position}
                      </div>
                      <div className="col-xl-2 col-sm-10">
                        <strong>Department</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {this.state.empDetails.department}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-2 col-sm-10">
                        <strong>Company Email</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {this.state.empDetails.company_email}
                      </div>
                      <div className="col-xl-2 col-sm-10">
                        <strong>Contact #</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {this.state.empDetails.contact_number}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-2 col-sm-10">
                        <strong>Rate(Hours)</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {(1*this.state.empDetails.hour_rate).toFixed(2)}
                      </div>
                      <div className="col-xl-2 col-sm-10">
                        <strong>Required Work(Hours)</strong>
                      </div>
                      <div className="col-xl-4 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {(1*this.state.empDetails.required_working_hours).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <h5 className="card-header">Attendance</h5>
                  <div className="card-body">
                    <div class="table-responsive mb-10">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr>
                            <th>Time In</th>
                            <th>Time Out</th>
                            <th>Date Created</th>
                            <th>Created by</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.coDetails.map((cutoff) => (
                            <>
                              <tr
                                data-toggle="collapse"
                                data-target={"#demo" + cutoff.id}
                                className="accordion-toggle"
                              >
                                <td>{cutoff.f_time_in}</td>
                                <td>{cutoff.f_time_out}</td>
                                <td>{cutoff.f_created_at}</td>
                                <td>{cutoff.created_by}</td>
                                {/* <td>
                                  <button className="btn btn-secondary btn-sm">
                                    <ChromeReaderMode />
                                  </button>
                                </td> */}
                              </tr>
                              {/* <tr>
                                <td colSpan="5" style={{ padding: "0px" }}>
                                  <div
                                    class="accordian-body collapse"
                                    // id={"demo" + cutoff.id}
                                  >
                                    <table class="table table-sm table-bordered table-striped mb-0">
                                      <thead>
                                        <tr>
                                          <th>Time In</th>
                                          <th>Time Out</th>
                                          <th>Date Created</th>
                                          <th>Created by</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </div>
                                </td>
                              </tr> */}
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Attendance);
