import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";

export default class EditSchemaDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      showEditModal: false,
      is_restday:props.schemaDetail.is_restday
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios.put("api/schema_detail/"+this.props.schemaDetail.id, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showEditModal: false });
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right mr-2"
          title="Edit Schema Detail"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Schema</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Description</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    defaultValue={this.props.schemaDetail.description}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Codition Type</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ condition_type: e.target.value })
                    }
                  >
                    <option value="">Select Schema</option>
                    {this.props.conditionDD.map((condition) => (
                      <option
                        key={condition.id}
                        value={condition.id}
                        selected={(condition.id == this.props.schemaDetail.condition_type) ? ("selected"):("")}
                      >
                        {condition.code + " - " + condition.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Step</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ step: e.target.value })}
                    defaultValue={this.props.schemaDetail.step}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Counter</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ counter: e.target.value })}
                    defaultValue={this.props.schemaDetail.counter}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Compute full hours?</label>
                  <div className="row">
                    <div className="col-sm-4">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          class="custom-control-input"
                          value="1"
                          onChange={(e) => {
                            this.setState({ is_restday: e.target.value });
                          }}
                          checked={this.state.is_restday == 1 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="customRadio1">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          class="custom-control-input"
                          value="0"
                          onChange={(e) => {
                            this.setState({ is_restday: e.target.value });
                          }}
                          checked={this.state.is_restday == 0 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="customRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Actual Rate (%)</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ actual_rate: parseFloat(e.target.value)/100 })
                    }
                    defaultValue={this.props.schemaDetail.actual_rate*100}
                  />
                </div>
              </div>
              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
