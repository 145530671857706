import React, { Component } from "react";
import Header from "../../components/Header";
import axios from "axios";
import EditSchema from "./EditSchema";
import AddSchemaDetail from "./AddSchemaDetail";
import EditSchemaDetail from "./EditSchemaDetail";
import DeleteSchemaDetail from "./DeleteSchemaDetail";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class SchemaDetail extends Component {
  state = {
    ability: cookies.get("ability")[0],
    schemaMain: [],
    schemaDetail: [],
    conditionDD: [],
  };

  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
    axios.get("api/condition").then((res) => {
      this.setState({ conditionDD: res.data });
    });
  }

  getDetails = () => {
    const schemaId = this.props.match.params.id;
    axios.get("api/schema/" + schemaId).then((res) => {
      this.setState({ schemaMain: res.data });
    });

    axios.get("api/schema_detail/" + schemaId).then((res) => {
      this.setState({ schemaDetail: res.data });
    });
  };

  handleCallback = (cb) => {
    if (cb) {
      this.getDetails();
      this.setState({ updateOccured: true });
    }
  };

  handleBack() {
    this.props.history.goBack();
  }

  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Header />
        <div>
          {this.state.ability.permission > 0 ? (
            <div className="container-fluid mt-xl-60 mt-sm-60 mt-35 px-xxl-60 px-xl-20">
              <div className="row pt-10 pb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Schema View</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <button
                    className="btn btn-sm btn-danger float-right"
                    onClick={this.handleBack}
                  >
                    Back to Previous Page
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>Details</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools">
                      {this.state.ability.permission > 2 ? (
                        <EditSchema
                          parentCallback={this.handleCallback}
                          schemaMain={this.state.schemaMain}
                        />):("")}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Code</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.schemaMain.code}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Description</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {this.state.schemaMain.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pb-10">
                <div className="col-lg-12 col-md-12 col-sm-12">
                {this.state.ability.permission > 1 ? (
                  <AddSchemaDetail
                    schema_id={this.props.match.params.id}
                    conditionDD={this.state.conditionDD}
                    parentCallback={this.handleCallback}
                  />):("")}
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-bordered table-striped mb-0">
                  <thead className="thead-secondary">
                    <tr>
                      <th>Step</th>
                      <th>Counter</th>
                      <th>Condition Type</th>
                      <th>Description</th>
                      <th>Compute full hours?</th>
                      <th>Actual Rate(%)</th>
                      <th style={{ width: "140px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.schemaDetail.length > 0 ? (
                      this.state.schemaDetail.map((detail) => (
                        <tr key={detail.id}>
                          <td>{detail.step}</td>
                          <td>{detail.counter}</td>
                          <td>{detail.condition_type_name}</td>
                          <td>{detail.description}</td>
                          <td>{detail.restday_qualified}</td>
                          <td className="text-right">
                            {detail.actual_rate * 100} %
                          </td>
                          <td>
                          {this.state.ability.permission > 3 ? (
                            <DeleteSchemaDetail
                              parentCallback={this.handleCallback}
                              id={detail.id}
                              name={detail.condition_type_name}
                            />):("")}
                            {this.state.ability.permission > 2 ? (
                            <EditSchemaDetail
                              conditionDD={this.state.conditionDD}
                              parentCallback={this.handleCallback}
                              schemaDetail={detail}
                            />):("")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No Data to show
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    );
  }
}
