import React from "react";
import { ProtectedRoute } from "./components/Protected.Route";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Public from "./pages/Public";
import Employee from "./pages/employees/Employee";
import ViewEmployee from "./pages/employees/ViewEmployee";
import LandingPage from "./pages/LandingPage";
import Cutoff from "./pages/timekeep/Cutoff";
import CutoffPerEmp from "./pages/timekeep/CutoffPerEmp";
import Attendance from "./pages/timekeep/Attendance";
import Overtime from "./pages/overtime/Overtime";
import OvertimePerEmployee from "./pages/overtime/OvertimePerEmployee";
import OvertimeEmpDetail from "./pages/overtime/OvertimeEmpDetail";
import Company from "./pages/companies/Company";
import ViewCompamy from "./pages/companies/ViewCompany";
import CalcSchema from "./pages/calcSchema/CalcSchema";
import SchemaDetail from "./pages/calcSchema/SchemaDetail";
import SessExpired from "./pages/SessExpired";
import UploadAttendanceList from "./pages/timekeep/UploadAttendanceList";
import EmpReport from "./pages/reports/EmpReport";
import TotalReport from "./pages/reports/TotalReport";
import TotalPerEmp from "./pages/reports/TotalPerEmp";
import ForgotPassword from "./pages/ForgotPassword";
import Administration from "./pages/administration/Administration";
import YearTotal from "./pages/reports/YearTotal";
import YearPerEmp from "./pages/reports/YearPerEmp";
import YearEmpReport from "./pages/reports/YearEmpReport";

export default function App() {
  return (
    <div className="App">
      <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/sess_expired" component={SessExpired}/>
        <ProtectedRoute exact path="/sitemanagement" component={Administration} />
        <ProtectedRoute exact path="/employee" component={Employee} />
        <ProtectedRoute exact path="/employee/view/:id" component={ViewEmployee} />
        <ProtectedRoute exact path="/attendance_upload" component={UploadAttendanceList} />
        <ProtectedRoute exact path="/cutoff" component={Cutoff} />
        <ProtectedRoute exact path="/cutoff/peremp/:id" component={CutoffPerEmp} />
        <ProtectedRoute exact path="/cutoff/peremp/view/:cutoff_id/:employee_id" component={Attendance} />
        <ProtectedRoute exact path="/overtime" component={Overtime} />
        <ProtectedRoute exact path="/overtime/:id" component={OvertimePerEmployee} />
        <ProtectedRoute exact path="/overtime/:cutoff_id/:employee_id" component={OvertimeEmpDetail} />
        <ProtectedRoute exact path="/company" component={Company} />
        <ProtectedRoute exact path="/company/view/:id" component={ViewCompamy} />
        <ProtectedRoute exact path="/schema" component={CalcSchema} />
        <ProtectedRoute exact path="/schema/view/:id" component={SchemaDetail} />
        <ProtectedRoute exact path="/app" component={Public} />
        <ProtectedRoute exact path="/report/total/:cutoff_id/:employee_id" component={EmpReport} />
        <ProtectedRoute exact path="/report/total/:id" component={TotalPerEmp} />
        <ProtectedRoute exact path="/report/total" component={TotalReport} />
        <ProtectedRoute exact path="/report/year_total" component={YearTotal} />
        <ProtectedRoute exact path="/report/year_total/:year" component={YearPerEmp} />
        <ProtectedRoute exact path="/report/year_total/:year/:employee_id" component={YearEmpReport} />
        <Route path="*" component={() => "404 NOT FOUND"} />

      </Switch>
      </Router>
    </div>
  );
}

