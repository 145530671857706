import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";

export default class AddSchemaDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      schema_id: props.schema_id,
      is_restday: 0,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios.post("api/schema_detail", this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showAddModal: false });
    });
  };

  render() {
    
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Schema Item"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Schema Item
        </button>
        <Modal
          size="md"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add New Schema Item</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Description</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Codition Type</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ condition_type: e.target.value })
                    }
                  >
                    <option value="">Select Schema</option>
                    {this.props.conditionDD.map((condition) => (
                      <option key={condition.id} value={condition.id}>
                        {condition.code + " - " + condition.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Step</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ step: e.target.value })}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Counter</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ counter: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Compute full hours?</label>
                  <div className="row">
                    <div className="col-sm-4">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          class="custom-control-input"
                          value="1"
                          onChange={(e) => {
                            this.setState({ is_restday: e.target.value });
                          }}
                          checked={this.state.is_restday == 1 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="customRadio1">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          class="custom-control-input"
                          value="0"
                          onChange={(e) => {
                            this.setState({ is_restday: e.target.value });
                          }}
                          checked={this.state.is_restday == 0 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="customRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Actual Rate (%)</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ actual_rate: parseFloat(e.target.value)/100 })
                    }
                  />
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
