import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage} from "@material-ui/icons";
import Cookies from "universal-cookie";

export default function TotalReport() {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[3];
  const [data, setData] = useState([]);
  const [companyDD, setCompanyDD] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/overtime";
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);
    };

    const fetchDD = async () => {
      const getCompany = await axios.get("api/company");
      setCompanyDD(getCompany.data);
    };

    if (!fileLoaded) {
      fetchData();
      fetchDD();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["file_name"]
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <Header />
      <div>
      {ability.permission > 0 ? (
        <div className="container-fluid">
          <div className="row mb-10">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3>Total Reports</h3>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="btn-group float-right">

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="table-wrap">
                <div class="row mb-10">
                  <div class="col-md-6">
                    <label>
                      <select
                        class="custom-select custom-select-sm form-control form-control-sm"
                        onChange={handleRecordPerPage}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>{" "}
                    items
                  </div>
                  <div class="col-md-6">
                    <input
                      type="search"
                      class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div class="table-responsive mb-10">
                  <table class="table table-sm table-bordered table-striped mb-0">
                  <thead className="thead-secondary">
                        <tr>
                          <th>Company</th>
                          <th>Period</th>
                          <th>OT Hours</th>
                          <th>Amount</th>
                          <th style={{width:"80px"}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.length > 0 ? (
                          currentData.map((cutoff) => (
                            <tr key={cutoff.id}>
                              <td>{cutoff.name}</td>
                              <td>
                                {cutoff.f_co_from_date +
                                  " - " +
                                  cutoff.f_co_to_date}
                              </td>
                              <td style={{textAlign:"right"}} >{(1*cutoff.total_ot_hours).toFixed(2)}</td>
                              <td style={{textAlign:"right"}} >{(1*cutoff.total_pay).toFixed(2)}</td>
                              <td>
                                <div className="btn-group">
                                  <Link
                                    to={{
                                      pathname: `/report/total/${cutoff.cutoff_id}`,
                                      query: `/report/total/`,
                                    }}
                                    className="btn btn-sm btn-outline-primary"
                                    title={"View per employee"}
                                  >
                                    <FindInPage />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Data to show
                            </td>
                          </tr>
                        )}
                      </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  {filteredData.length > 0
                    ? "Showing " +
                      (startIndex + 1) +
                      " to " +
                      (endIndex > filteredData.length
                        ? filteredData.length
                        : endIndex) +
                      " of " +
                      filteredData.length
                    : ""}
                </div>
                <div class="col-sm-6 col-md-6">
                  <ul class="pagination float-right">
                    {pages.map((number) => (
                      <li
                        key={number}
                        id={number}
                        className={
                          currentPage == number
                            ? "paginate_button page-item active"
                            : "paginate_button page-item"
                        }
                        onClick={() => setCurrentPage(number)}
                      >
                        <a class="page-link">{number}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="m-0 text-center">
          The Credentials Supplied Are Not Sufficient To Access This Module
          <br />
          Please Contact Administrator
        </h1>
      )}
      </div>
    </div>
  );
}
