import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CloudUpload } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class UploadAttendance extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      companyDD:[],
      disableAdd: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append('uploadFile', this.state.actual_file);
    formData.append('co_from_date', this.state.co_from_date);
    formData.append('co_to_date', this.state.co_to_date);
    formData.append('file_name', this.state.file_name);
    formData.append('company_id', this.state.company_id);
    formData.append('updated_by', cookies.get('email'));
    formData.append('created_by', cookies.get('email'));
    axios.post("api/upload", formData).then((res) => {
      this.setState({ disableAdd: false, showAddModal: false });
      this.props.parentCallback(true);
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm"
          title="Upload Cutoff Attendance"
          onClick={this.handleshowAddModal}
        >
          <CloudUpload /> Upload Cutoff Attendance
        </button>
        <Modal
          size="md"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Cutoff Attendance</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                  <label>Company</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ company_id: e.target.value })
                    }
                  >
                    <option value="">Select Company</option>
                    {this.props.companyDD.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Period From</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ co_from_date: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>From To</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ co_to_date: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>File</label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".xlsx,.xls"
                    required
                    onChange={(e) =>
                      this.setState({
                        actual_file: e.target.files[0],
                        file_name: e.target.files[0].name
                      })
                    }
                  />
                </div>
              </div>
              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2" disabled={this.state.disableAdd}>
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
