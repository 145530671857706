import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage, Add } from "@material-ui/icons";
import ReactExport from "react-data-export";
import Cookies from "universal-cookie";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function TotalPerEmp(props) {
  
  const cookies = new Cookies();
  const ability = cookies.get("ability")[3];
  const [data, setData] = useState([]);
  const [parentCutoff, setParentCutoff] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/overtime/" + props.match.params.id;
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
    };

    const fetchCutoff = async () => {
      let apiLink = "api/cutoff/" + props.match.params.id;
      const res = await axios.get(apiLink);
      setParentCutoff(res.data);
    };
    fetchData();
    fetchCutoff();
  }, []);

  const handleBack = () => {
    props.history.goBack();
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["first_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const getTotalOT = filteredData.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.total_ot_hours)),
    0
  );

  const getTotalAmt = filteredData.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.total_pay)),
    0
  );
  const getTotalAmtNet = filteredData.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.total_net_pay)),
    0
  );

  const dataSet = [
    {
      columns: [{ title: "Company" }, { title: parentCutoff.company_name }],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "Period" },
        {
          title:
            parentCutoff.f_co_from_date + " - " + parentCutoff.f_co_to_date,
        },
      ],
      data: [["", ""]],
    },
    {
      columns: [
        { title: "Name" },
        { title: "Department" },
        { title: "Position" },
        { title: "Overtime Hours" },
        { title: "Overtime Pay(Gross)" },
        { title: "Overtime Pay(Net)" },
        { title: "Tax Rate(%)" },
      ],
      data: filteredData.map((order, index) => {
        return [
          { value: order.first_name + " " + order.last_name },
          { value: order.department },
          { value: order.position },
          { value: parseFloat(order.total_ot_hours).toFixed(2) },
          { value: parseFloat(order.total_pay).toFixed(2) },
          { value: parseFloat(order.total_net_pay).toFixed(2) },
          { value: "" + (order.ot_tax * 100).toFixed(2) },
        ];
      }),
    },
    {
      columns: [
        { title: "" },
        { title: "" },
        { title: "TOTAL" },
        { title: "" + getTotalOT.toFixed(2) },
        { title: "" + getTotalAmt.toFixed(2) },
        { title: "" + getTotalAmtNet.toFixed(2) },
      ],
      data: [["", "", "", "", "", ""]],
    },
  ];

  return (
    <div>
      <Header />
      <div>
      {ability.permission > 0 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3 className="pb-10">Overtime Per Employee</h3>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <button
                className="btn btn-sm btn-danger float-right "
                onClick={handleBack}
              >
                Back to Previous Page
              </button>
              {ability.permission > 2 ? (
              <ExcelFile
                element={
                  <button className="btn btn-sm btn-success float-right mr-10">
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i> Export
                    to Excel
                  </button>
                }
                filename={
                  "Total Overtime " +
                  parentCutoff.company_name +
                  " " +
                  parentCutoff.f_co_from_date +
                  " - " +
                  parentCutoff.f_co_to_date
                }
              >
                <ExcelSheet dataSet={dataSet} name="Total Overtime" />
              </ExcelFile>):("")}
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-wrap">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row mb-10">
                            <div className="col-xl-2 col-sm-10">
                              <strong>Company</strong>
                            </div>
                            <div className="col-xl-4 col-sm-10">
                              :&nbsp;&nbsp;&nbsp;
                              {parentCutoff.company_name}
                            </div>
                          </div>

                          <div className="row mb-10">
                            <div className="col-xl-2 col-sm-10">
                              <strong>Period</strong>
                            </div>
                            <div className="col-xl-6 col-sm-10">
                              :&nbsp;&nbsp;&nbsp;
                              {parentCutoff.f_co_from_date}
                              {" - "}
                              {parentCutoff.f_co_to_date}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-10">
                    <div className="col-md-9"></div>
                    <div className="col-md-3 align-bottom">
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="table-responsive mb-10">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-secondary">
                        <tr>
                          <th>Name</th>
                          <th>Department</th>
                          <th>Position</th>
                          <th>OT Hours</th>
                          <th>Overtime Pay (Gross)</th>
                          <th>Overtime Pay (Net)</th>
                          <th>Tax Rate</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.length > 0 ? (
                          filteredData.map((cutoff) => (
                            <tr key={cutoff.id}>
                              <td>
                                {cutoff.last_name + ", " + cutoff.first_name}
                              </td>
                              <td>{cutoff.department}</td>
                              <td>{cutoff.position}</td>
                              <td style={{ textAlign: "right" }}>
                                {(1*cutoff.total_ot_hours).toFixed(2)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(1*cutoff.total_pay).toFixed(2)}
                              </td>

                              <td style={{ textAlign: "right" }}>
                                {(1*cutoff.total_net_pay).toFixed(2)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(cutoff.ot_tax * 100).toFixed(2)} %
                              </td>
                              <td>
                                <div className="btn-group">
                                  <Link
                                    to={{
                                      pathname: `/report/total/${cutoff.cutoff_id}/${cutoff.employee_id}`,
                                      query: `/report/total/`,
                                    }}
                                    className="btn btn-sm btn-outline-primary"
                                    title={"View per employee"}
                                  >
                                    <FindInPage />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Data to show
                            </td>
                          </tr>
                        )}
                        {filteredData.length > 0 ? (
                          <tr>
                            <td colSpan="3" className="text-right">
                              <strong>TOTAL</strong>
                            </td>
                            <td className="text-right">
                              <strong>{getTotalOT.toFixed(2)}</strong>
                            </td>
                            <td className="text-right">
                              <strong>{getTotalAmt.toFixed(2)}</strong>
                            </td>
                            <td className="text-right">
                              <strong>{getTotalAmtNet.toFixed(2)}</strong>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="m-0 text-center">
          The Credentials Supplied Are Not Sufficient To Access This Module
          <br />
          Please Contact Administrator
        </h1>
      )}
      </div>
    </div>
  );
}
