import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ChangePassword extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      UID: cookies.get("uid"),
      withErrors: false,
      errors: [],
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("api/change_password/" + this.state.UID, this.state)
      .then((res) => {
        this.setState({ showAddModal: false, withErrors: false });
      })
      .catch((err) => {
        this.setState({ withErrors: true, errors: err.response.data.errors });
      });
  };

  render() {
    return (
      <>
        <Link className="dropdown-item" onClick={this.handleshowAddModal}>
          <i className="dropdown-icon zmdi zmdi-key"></i>
          Change Password
        </Link>
        <Modal
          size="md"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Change Password</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                {this.state.withErrors ? (
                  <div class="form-group col-md-12">
                    <div class="form-group alert alert-danger col-md-12">
                      <div>{this.state.errors.password}</div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group col-md-12 col-sm-12">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ password_confirmation: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
