import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";

export default function OvertimeEmpDetail(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const [data, setData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [cutoff, setCutoff] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let apiLink =
        "api/overtime_detail/" +
        props.match.params.cutoff_id +
        "/" +
        props.match.params.employee_id;
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
    };

    const fetchEmp = async () => {
      let apiLink = "api/employee/" + props.match.params.employee_id;
      const res = await axios.get(apiLink);
      setEmployee(res.data);
    };

    const fetchCutoff = async () => {
      let apiLink = "api/cutoff/" + props.match.params.cutoff_id;
      const res = await axios.get(apiLink);
      setCutoff(res.data);
    };
    fetchEmp();
    fetchCutoff();
    fetchData();
  }, []);

  const handleBack = () => {
    props.history.goBack();
  };
  console.log(props);
  return (
    <div>
      <Header />
      <div>
      {ability.permission > 0 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <h3 className="pb-10">Overtime Details</h3>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12">
              <button
                className="btn btn-sm btn-danger float-right "
                onClick={handleBack}
              >
                Back to Previous Page
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card">
                {/* <div className="card-header card-header-action">
                    <h5></h5>
                    <div className="d-flex align-items-center card-action-wrap-tools">
                    </div>
                  </div> */}
                <div className="card-body">
                  <div className="row mb-10">
                    <div className="col-xl-4 col-sm-10">
                      <strong>Name</strong>
                    </div>
                    <div className="col-xl-8 col-sm-10">
                      :&nbsp;&nbsp;&nbsp;
                      {employee.last_name + ", " + employee.first_name}
                    </div>
                  </div>

                  <div className="row mb-10">
                    <div className="col-xl-4 col-sm-10">
                      <strong>Company</strong>
                    </div>
                    <div className="col-xl-8 col-sm-10">
                      :&nbsp;&nbsp;&nbsp;
                      {cutoff.company_name}
                    </div>
                  </div>

                  <div className="row mb-10">
                    <div className="col-xl-4 col-sm-10">
                      <strong>Period</strong>
                    </div>
                    <div className="col-xl-8 col-sm-10">
                      :&nbsp;&nbsp;&nbsp;
                      {cutoff.f_co_from_date + " - " + cutoff.f_co_to_date}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="table-responsive mb-10">
                <table className="table table-sm table-bordered table-striped mb-0">
                  <thead className="thead-secondary">
                    <tr>
                      <th>Date</th>
                      <th>Time In</th>
                      <th>Time Out</th>
                      <th>Description</th>
                      <th>Overtime Hours(Minutes)</th>
                      <th>Overtime Rate</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((detail) => (
                      <tr>
                        <td>{detail.f_date}</td>
                        <td>
                        {(detail.condition_id % 3 == 0) ? (detail.f_nd_time_in):(detail.f_time_in)}
                        </td>
                        <td>{detail.condition_id % 3 != 0
                              ? detail.is_nd == 1 ? detail.f_nd_time_in : detail.f_time_out
                              : detail.f_time_out}</td>
                        <td>{detail.code + " - " + detail.description}</td>
                        <td className="text-right">{(1*detail.ot_minutes).toFixed(2)}</td>
                        <td className="text-right">{(1*detail.actual_rate).toFixed(2)}</td>
                        <td className="text-right">{(detail.ot_minutes*detail.actual_rate_mins).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="m-0 text-center">
          The Credentials Supplied Are Not Sufficient To Access This Module
          <br />
          Please Contact Administrator
        </h1>
      )}
      </div>
    </div>
  );
}
