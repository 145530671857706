import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const domainURL = '.uplines.net';

class Auth {
  constructor() {
    this.authenticated = false;
    this.errors = "";
    if (cookies.get("token")) {
      this.authenticated = true;
    }
  }

  login(creds) {

    axios.get("sanctum/csrf-cookie").then(() => {
      axios
        .post("api/login", creds)
        .then((res) => {
          this.authenticated = true;
          let expTime = new Date(Date.now() + 7200000);
          // cookies.set("token", res.data.token, { expires: expTime });
          // cookies.set("name", res.data.user.name, { expires: expTime });
          // cookies.set("email", res.data.user.email, { expires: expTime });
          // cookies.set("uid", res.data.user.id, { expires: expTime });
          // cookies.set("ability",res.data.ability, {expires: expTime});
          // cookies.remove("badCreds");

          cookies.set("token", res.data.token, { path: '/', domain: domainURL, expires: expTime});
          cookies.set("name", res.data.user.name, { path: '/', domain: domainURL, expires: expTime});
          cookies.set("uid", res.data.user.id, { path: '/', domain: domainURL, expires: expTime});
          cookies.set("email", res.data.user.email, { path: '/', domain: domainURL, expires: expTime});
          cookies.set("ability",res.data.ability, { path: '/', domain: domainURL, expires: expTime});
          cookies.remove("badCreds", { path: "/", domain: domainURL });
          
          window.location.reload();
        })
        .catch((err) => {
          this.authenticated = false;
          this.errors = "invalid";
          let expTime = new Date(Date.now() + 7200000);
          cookies.set("badCreds", "Invalid Email or Password", { expires: expTime });
        });
    });

    return this.errors;
  }

  logout() {
    axios.get("sanctum/csrf-cookie").then(() => {
      axios.post("api/logout").then((res) => {
        this.authenticated = false;
        cookies.remove("token", { path: "/", domain: domainURL});
        cookies.remove("name", { path: "/", domain: domainURL});
        cookies.remove("uid", { path: "/", domain: domainURL});
        cookies.remove("email", { path: "/", domain: domainURL});
        cookies.remove("ability", { path: "/", domain: domainURL});
        cookies.remove("badCreds", { path: "/", domain: domainURL});
        
        // cookies.remove("token");
        // cookies.remove("name");
        // cookies.remove("email");
        // cookies.remove("uid");
        // cookies.remove("ability");
        // cookies.remove("badCreds");
        window.location.reload();
      });
    });
  }

  isAuthenticated() {
    return this.authenticated;
  }

  checklogin () {
    if (cookies.get("token")) {
      
    } else {
      window.location.replace("/#/sess_expired");
    }
  }
}

export default new Auth();
