import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ForgotPassword extends Component {
  state = {
    submitted: false,
    withErrors: false,
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: this.email,
    };
    axios.get("sanctum/csrf-cookie").then((res) => {
      axios
        .post("api/forgot_password", data)
        .then((res) => {
          console.log(res.data);
          this.setState({ submitted: true });
          this.setState({ withErrors: false });
        })
        .catch((err) => {
          this.setState({ submitted: false });
          this.setState({ withErrors: true });
        });
    });
  };

  render() {
    if (cookies.get("token")) {
      this.props.history.push("/app");
    }

    return (
      <div className="hk-wrapper">
        <div className="hk-pg-wrapper hk-auth-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 pa-0">
                <div className="auth-form-wrap pt-xl-0 pt-70 landing">
                  <div className="auth-form w-xl-35 w-lg-65 w-sm-85 w-100 card pa-25 shadow-lg">
                    <div className="media text-center mb-20">
                      <div className="media-img-wrap">
                        <img
                          className="brand-img"
                          src="logo192.png"
                          alt="brand"
                        />
                      </div>
                      <div class="media-body">
                        <h2>Overtime Calculation System</h2>
                      </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          required
                          onChange={(e) => (this.email = e.target.value)}
                        />
                      </div>
                      <button
                        className="btn btn-success btn-block"
                        type="submit"
                      >
                        Reset Password
                      </button>
                      <p className="font-14 text-center mt-15">
                        <Link to="/">Back to Login</Link>
                      </p>
                      {this.state.submitted ? (
                        <blockquote class="blockquote mt-15">
                          <div>Password sent to email</div>
                          </blockquote>
                      ) : (
                        ""
                      )}

                      {this.state.withErrors ? (
                        <blockquote class="blockquote mt-15">
                          <div>
                            Password sent to email, Please send new request after 15
                            minutes
                          </div>
                          </blockquote>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
