import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { timeFormatter } from "../../components/DtFormatter";
import { Edit } from "@material-ui/icons";
import TimePicker from "react-bootstrap-time-picker";
import { timeFromInt } from "time-number";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class AddWeekSchedule extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      monDate: null,
      tueDate: null,
      wedDate: null,
      thuDate: null,
      friDate: null,
      satDate: null,
      sunDate: null,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  pad =(n)=> {
    return n < 10 ? "0" + n : n;
  }

  weekChange = (e) => {
    const fullWeek = e.target.value;

    let weekYear = parseInt(fullWeek.substring(0, 4));
    let weekNo = parseInt(fullWeek.substring(6, 8));
    let simple = new Date(weekYear, 0, 1 + (weekNo - 1) * 7);
    let dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    let weekMonth = ISOweekStart.getMonth();

    console.log(
      weekYear + " " + (weekMonth + 1) + " " + ISOweekStart.getDate()
    );
    this.setState({
      monDate: weekYear + "-" + (weekMonth + 1) + "-" + this.pad(ISOweekStart.getDate()),
      tueDate: weekYear + "-" + (weekMonth + 1) + "-" + this.pad(ISOweekStart.getDate()+1),
      wedDate: weekYear + "-" + (weekMonth + 1) + "-" + this.pad(ISOweekStart.getDate()+2),
      thuDate: weekYear + "-" + (weekMonth + 1) + "-" + this.pad(ISOweekStart.getDate()+3),
      friDate: weekYear + "-" + (weekMonth + 1) + "-" + this.pad(ISOweekStart.getDate()+4),
      satDate: weekYear + "-" + (weekMonth + 1) + "-" + this.pad(ISOweekStart.getDate()+5),
      sunDate: weekYear + "-" + (weekMonth + 1) + "-" + this.pad(ISOweekStart.getDate()+6),
    });
  };

  

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm"
          title="Add Work Week Schedule"
          onClick={this.handleshowAddModal}
        >
          <Edit />
        </button>
        <Modal
          size="xl"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add Work Week Schedule</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Work Week</label>
                  <input
                    type="week"
                    className="form-control"
                    required
                    onChange={this.weekChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Monday</label>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        readOnly={true}
                        value={this.state.monDate}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Rest Day</label>
                      <select className="form-control">
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time In</label>
                      <input type="time" className="form-control" required />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time Out</label>
                      <input type="time" className="form-control" required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Tuesday</label>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        readOnly={true}
                        value={this.state.tueDate}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Rest Day</label>
                      <select className="form-control">
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time In</label>
                      <input type="time" className="form-control" required />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time Out</label>
                      <input type="time" className="form-control" required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Wednesday</label>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        readOnly={true}
                        value={this.state.wedDate}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Rest Day</label>
                      <select className="form-control">
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time In</label>
                      <input type="time" className="form-control" required />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time Out</label>
                      <input type="time" className="form-control" required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Thursday</label>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        readOnly={true}
                        value={this.state.thuDate}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Rest Day</label>
                      <select className="form-control">
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time In</label>
                      <input type="time" className="form-control" required />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time Out</label>
                      <input type="time" className="form-control" required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Friday</label>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        readOnly={true}
                        value={this.state.friDate}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Rest Day</label>
                      <select className="form-control">
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time In</label>
                      <input type="time" className="form-control" required />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time Out</label>
                      <input type="time" className="form-control" required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Saturday</label>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        readOnly={true}
                        value={this.state.satDate}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Rest Day</label>
                      <select className="form-control">
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time In</label>
                      <input type="time" className="form-control" required />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time Out</label>
                      <input type="time" className="form-control" required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Sunday</label>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        readOnly={true}
                        value={this.state.sunDate}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Rest Day</label>
                      <select className="form-control">
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time In</label>
                      <input type="time" className="form-control" required />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Time Out</label>
                      <input type="time" className="form-control" required />
                    </div>
                  </div>
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
