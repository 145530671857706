import React, { useState, useEffect } from "react";
import auth from "./Auth";
import { Menu, MenuOpen } from "@material-ui/icons";
import {
  HashRouter as Router,
  Route,
  Switch,
  NavLink,
  Link,
} from "react-router-dom";
import Cookies from "universal-cookie";
import UpdateUserDetails from "./UpdateUserDetail";
import ChangePassword from "./ChangePassword";
const cookies = new Cookies();
const ability = cookies.get("ability");

export default function Header(props) {
  auth.checklogin();
  
  const [fileLoaded, setFileLoaded] = useState(false);
  
  useEffect(() => {
    if (!fileLoaded) {
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

 
  return (
    <div className="hk-wrapper hk-vertical-nav">
      <nav className="navbar navbar-expand-xl navbar-dark fixed-top hk-navbar bg-danger">
        <Link
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Menu />
        </Link>
        <Link className="navbar-brand" to="/app">
          <img
            src="/fast-whole.jpg"
            className="img-responsive rounded"
            style={{ width: "50px" }}
          />
        </Link>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-0">
            {ability[0].permission > 0 ? (
              <li
                className={
                  window.location.href.includes("schema") ||
                  window.location.href.includes("company") ||
                  window.location.href.includes("employee")
                    ? "nav-item dropdown show"
                    : "nav-item dropdown"
                }
              >
                <Link
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Master Data
                </Link>
                <div className="dropdown-menu">
                  <NavLink className="dropdown-item" to="/schema">
                    Schemas
                  </NavLink>
                  <NavLink className="dropdown-item" to="/company">
                    Companies
                  </NavLink>
                  <NavLink className="dropdown-item" to="/employee">
                    Employees
                  </NavLink>
                </div>
              </li>
            ) : (
              ""
            )}
            {ability[1].permission > 0 ? (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/attendance_upload">
                    Timesheet Upload
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/cutoff">
                    View Timesheet
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}
            {ability[3].permission > 0 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/overtime">
                  Compute Overtime
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {ability[2].permission > 0 ? (
              <li
                className={
                  window.location.href.includes("report")
                    ? "nav-item dropdown show"
                    : "nav-item dropdown"
                }
              >
                <Link
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Reports
                </Link>
                <div className="dropdown-menu">
                  {/* <NavLink className="dropdown-item" to="/report/emp">
                  Employee
                </NavLink> */}
                  <NavLink className="dropdown-item" to="/report/total">
                    Total Overtime
                  </NavLink>
                  <NavLink className="dropdown-item" to="/report/year_total">
                    Year Total
                  </NavLink>
                </div>
              </li>
            ) : (
              ""
            )}
          </ul>

          <ul className="navbar-nav hk-navbar-content order-md-2">
            <li className="nav-item dropdown dropdown-authentication show">
              <a
                className="nav-link dropdown-toggle no-caret"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <div className="media">
                  <div className="media-img-wrap"></div>
                  <div className="media-body">
                    <span>
                      <i className="material-icons mr-1">account_circle</i>
                      {cookies.get("name")}
                      <i className="zmdi zmdi-chevron-down"></i>
                    </span>
                  </div>
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                data-dropdown-in="flipInX"
                data-dropdown-out="flipOutX"
              >
                <div className="dropdown-item">
                  <strong>
                    {cookies.get("name")}
                    <br />
                    {"(" + cookies.get("email") + ")"}
                  </strong>
                </div>
                <UpdateUserDetails parentCallback = {handleAddCallback} />
                <ChangePassword />
                {ability[4].permission > 0 ? (
                  <Link
                    styles={"cursor:pointer"}
                    className="dropdown-item"
                    to="/sitemanagement"
                  >
                    <i className="dropdown-icon zmdi zmdi-view-list"></i>
                    Site Management
                  </Link>
                ) : (
                  ""
                )}
                <a
                  styles={"cursor:pointer"}
                  className="dropdown-item"
                  target="_blank"
                  href={
                    process.env.REACT_APP_API_LINK + "OT_Calculation_System_User_Guide.pdf"
                  }
                >
                  <i className="dropdown-icon zmdi zmdi-help-outline"></i>
                  OTC Quick Reference Guide
                </a>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    auth.logout();
                  }}
                >
                  <i className="dropdown-icon zmdi zmdi-power"></i>
                  <span>Log out</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div className="mb-45"> className</div>
    </div>
  );
}
