import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const empStatus = ["Probationary", "Regular", "Resigned"];
export default class EditEmployee extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get('email'),
      showEditModal: false,
    };

    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("api/employee/" + this.props.empDetail.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showEditModal: false });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm"
          title="Edit Employee Details"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="xl"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Employee Details</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-4 col-sm-12">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ first_name: e.target.value })
                    }
                    defaultValue={this.props.empDetail.first_name}
                  />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ mid_name: e.target.value })
                    }
                    defaultValue={this.props.empDetail.mid_name}
                  />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ last_name: e.target.value })
                    }
                    defaultValue={this.props.empDetail.last_name}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Employee #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ emp_no: e.target.value })}
                    defaultValue={this.props.empDetail.emp_no}
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Date of Birth</label>
                  <input
                    class="form-control"
                    type="date"
                    onChange={(e) =>
                      this.setState({ date_of_birth: e.target.value })
                    }
                    defaultValue={this.props.empDetail.date_of_birth}
                  />
                </div>
              </div>
              
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Rate(Hours)</label>
                  <input
                    type="number"
                    step=".00001"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({
                        hour_rate: e.target.value,
                        minute_rate: e.target.value * 60,
                      })
                    }
                    defaultValue={this.props.empDetail.hour_rate}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Tax Rate(%)</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({
                        ot_tax: e.target.value/100,
                      })
                    }
                    defaultValue={this.props.empDetail.ot_tax*100}
                  />
                </div>
              </div>
              
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Company</label>
                  <select
                    class="form-control custom-select"
                    onChange={(e) =>
                      this.setState({ company_id: e.target.value })
                    }
                  >
                    {this.props.companyDD.map((company) => (
                      <option
                        key={company.id}
                        value={company.id}
                        selected={
                          this.props.empDetail.company_id == company.id
                            ? "selected"
                            : ""
                        }
                      >
                        {company.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Company Email</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ company_email: e.target.value })
                    }
                    defaultValue={this.props.empDetail.company_email}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Department</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ department: e.target.value })
                    }
                    defaultValue={this.props.empDetail.department}
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Position</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ position: e.target.value })
                    }
                    defaultValue={this.props.empDetail.position}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Contact #</label>
                  <input
                    type="tel"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ contact_number: e.target.value })
                    }
                    defaultValue={this.props.empDetail.contact_number}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Status</label>
                  <select
                    class="form-control custom-select"
                    onChange={(e) =>
                      this.setState({ emp_status: e.target.value })
                    }
                  >
                    <option
                      value={0}
                      selected={
                        this.props.empDetail.emp_status == 0 ? "selected" : ""
                      }
                    >
                      {empStatus[0]}
                    </option>
                    <option
                      value={1}
                      selected={
                        this.props.empDetail.emp_status == 1 ? "selected" : ""
                      }
                    >
                      {empStatus[1]}
                    </option>
                    <option
                      value={2}
                      selected={
                        this.props.empDetail.emp_status == 2 ? "selected" : ""
                      }
                    >
                      {empStatus[2]}
                    </option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Hire Date</label>
                  <input
                    class="form-control"
                    type="date"
                    defaultValue={this.props.empDetail.date_hired}
                    onChange={(e) =>
                      this.setState({ date_hired: e.target.value })
                    }
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Schema</label>
                  <select
                    class="form-control custom-select"
                    onChange={(e) =>
                      this.setState({ schema_id: e.target.value })
                    }
                  >
                    {this.props.schemaDD.map((schema) => (
                      <option
                        key={schema.id}
                        value={schema.id}
                        selected={
                          this.props.empDetail.schema_id == schema.id
                            ? "selected"
                            : ""
                        }
                      >
                        {schema.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
